// VAR'S
// ============================\\



$primaryColor: #266ea1;

/************* LOGO *************/
$logoWidth: 213px;
$logoHeight: 96px;
$logoMarginTop: 0;
$logoImage: image-url('whitelabels/logo-hoorn.svg'); 

/************* header *************/
$showHeaderLinks: false;
$headerNavColor: #FFF;
$headerNavBg: #266ea1;
$headerBorderBottom: none;
$headerMarginBottom: 20px;
$headerBg: #747779;
$headerWidth: 1013px;
$headerRight: true;
$headerNavRight: 25px;
$headerDisplay: inline-block;

/************* AUTH *************/
$authBgColor: #e8e9ea;
$authTextColor: #333;
$authRequestColor: #333;

/************* INFO BLOCK *************/
$infoBlockColor: #353b40;
$infoBlockBg: #FFF;
$infoBlockBoxShadow: none;

/************* CONTENT *************/
$contentPadding: 0;
$contentBg: #FFF;
$contentBgGenerator: #FFF;
$contentBoxShadow: none;
$contentH1Padding: 10px;

/************* FOOTER *************/
$footerShowHouses: false;
$footerAllBg: #e8e9ea;
$footerColorBg: transparent;

/************* FORM CONTAINER *************/
$formContainerBg: #FFF;
$formContainerPadding: 0;

/************* OTHER *************/
$mainBg: none;
$containerBg: #FFF;
$containerInnerSmallBg: none;
$containerInnerLargeBg: none;
$schetsBottomBg: none;
$schetsBottomBgGenerator: none;
$color-border2: #e8e9ea;



@import '../application'
